// Gallery.jsx
import React from 'react';
import Layout from "../components/layout";
import {StaticImage} from "gatsby-plugin-image";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

const GalleryAustaliaTwoThousand = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>Australia 2000 - 2010</h1>
            <h2>October 2010</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/October2010/2145575_orig.jpg"
                             alt="Sensei Simon teaching at Sensei Dave's dojo - Caboolture."/><StaticImage
                src="../images/gallery/Australia2000/October2010/407618_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2000/October2010/4136238_orig.jpg"
                alt="Delaneys Creek Dojo. Simon, Shane, Brad, Ray and Will (front)."/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Chikara Ryu Seminar 2010</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/ChikaraRyuSeminar2010/1183620_orig.jpg"
                             alt="With Chikara Ryu students - Shihan Kyl Reber's Zen Do Kai club, Graceville, Brisbane. Oct 2010."/><StaticImage
                src="../images/gallery/Australia2000/ChikaraRyuSeminar2010/1478285_orig.jpg"
                alt="Jo Golland, Shihan Kyl Reber, Sensei Simon Golland. Simon's Goju Ryu Seminar at Kyl's Zen Do Kai dojo."/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Dansha grading 2010</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/Danshagrading2010/3146218_orig.jpg"
                             alt="Sensei Rob Williams, Sensei Dave Martin, Nick Gault (Shaodan grading), Barry."/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2010/896644_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Dansha grading 2009</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/Danshagrading2009/1494972_orig.jpg"
                             alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2009/9280790_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2009/9371023_orig.jpg"
                alt="Sensei Dave Martin, Shodan Jake Acutt"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>April 2008</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/April2008/8382110_orig.jpg"
                             alt="Back: Mike Lehma, Simon Golland, Sensei Ravey, Dave Martin. Front: Jacinda Wilson, Barry, Brad Doust, Don Weir, Jo Golland."/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Kilcoy Dojo, April 2007</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/KilcoyDojoApril2007/3520714_orig.jpg"
                             alt="Don Weir, Dave MArtin, Brad Groves, Sue Groves, Bill Groves, Sensei Ravey"/><StaticImage
                src="../images/gallery/Australia2000/KilcoyDojoApril2007/4252025_orig.jpg"
                alt="Sensei Ravey, Brad Groves, Sue Groves, Dave Martin"/><StaticImage
                src="../images/gallery/Australia2000/KilcoyDojoApril2007/820334_orig.jpg"
                alt="Don Weir, Brad Groves, Sesei Ravey"/><StaticImage
                src="../images/gallery/Australia2000/KilcoyDojoApril2007/9381543_orig.jpg"
                alt="Dave Martin, Don Weir, Sue Graves, Brad Groves, Sensei Ravey"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Dansha grading 2006</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/Danshagrading2006/1609504_orig.jpg"
                             alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2006/2741179_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2006/5448032_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2006/728337_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Beach training, Bribie Island 2004, 2005 and 2006</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage
                    src="../images/gallery/Australia2000/BeachtrainingBribieIsland20042005and2006/1527646_orig.jpg"
                    alt="Sunday 2 April 2006 at Bribie Island starting at 7am. Caboolture, Delaney’s Creek and Kilcoy Dojos."/><StaticImage
                src="../images/gallery/Australia2000/BeachtrainingBribieIsland20042005and2006/2511190_orig.jpg"
                alt="Bribie Island, Dec 4, 2005"/><StaticImage
                src="../images/gallery/Australia2000/BeachtrainingBribieIsland20042005and2006/541863_orig.jpg"
                alt=""/><StaticImage
                src="../images/gallery/Australia2000/BeachtrainingBribieIsland20042005and2006/6401889_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2000/BeachtrainingBribieIsland20042005and2006/835098_orig.jpg"
                alt=""/><StaticImage
                src="../images/gallery/Australia2000/BeachtrainingBribieIsland20042005and2006/8603075_orig.jpg"
                alt="Bribie Island Gasshuku Dec 4, 2005"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Newcastle IOGKF Gasshuku 2006</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/NewcastleIOGKFGasshuku2006/5531624_orig.jpg"
                             alt="IOGKF - Newcastle Gasshuku with Sensei Graham Ravey March 2006, TOGKA participants from left to right, Tom Mayfield, Barry Benbow, David Martin, Sensei Graham Ravey, Helmut Leitner, Don Weir"/><StaticImage
                src="../images/gallery/Australia2000/NewcastleIOGKFGasshuku2006/5819317_orig.jpg"
                alt="IOGKF - Newcastle Gasshuku hosted by Sensei Chris Larken, with Sensei Graham Ravey March 2006, Gasshuku group photo on final day 19 March 2006"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Canberra IOGKF Gasshuku 2005</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/CanberraIOGKFGasshuku2005/1371371_orig.jpg"
                             alt="IOGKF - Canberra Gasshuku with Sensei Graham Ravey December 2005, IOGKF Australia group photo"/><StaticImage
                src="../images/gallery/Australia2000/CanberraIOGKFGasshuku2005/1405164_orig.jpg"
                alt="IOGKF - Canberra Gasshuku with Sensei Graham Ravey December 2005, Sensei Graham Ravey and Kevin Nash 3rd Dan"/><StaticImage
                src="../images/gallery/Australia2000/CanberraIOGKFGasshuku2005/6177008_orig.jpg"
                alt="IOGKF - Canberra Gasshuku with Sensei Graham Ravey December 2005, Sensei Graham Ravey and Chris Larken 6th Dan"/><StaticImage
                src="../images/gallery/Australia2000/CanberraIOGKFGasshuku2005/9063853_orig.jpg"
                alt="IOGKF - Canberra Gasshuku with Sensei Graham Ravey December 2005, David Lambert Jnr and Sensei Graham Ravey"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Bilambil Heights Gasshuku 2004</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/BilambilHeightsGasshuku2004/2545218_orig.jpg"
                             alt="Gasshuku 16-17 October 2004 at Bilambil Heights Qld, Black belt group photo"/><StaticImage
                src="../images/gallery/Australia2000/BilambilHeightsGasshuku2004/615078_orig.jpg"
                alt="Karate training with Sensei Ravey at Sensei Mike Lehmans place"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Perth Gasshuku 2004</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/PerthGasshuku2004/8276748_orig.jpg"
                             alt="Perth Gasshuku April 2004, Sensei Ravey in Black Gi and to left Sensei Terry Lyon"/><StaticImage
                src="../images/gallery/Australia2000/PerthGasshuku2004/8311415_orig.jpg"
                alt="Perth Gasshuku April 2004, Soles after hours of Kata training"/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Dansha grading 2003</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/Danshagrading2003/2598248_orig.jpg"
                             alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2003/5774448_orig.jpg" alt=""/>
            </SRLWrapper></SimpleReactLightbox>
            <h2>Dansha grading 2002</h2>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/Australia2000/Danshagrading2002/1338583_orig.jpg"
                             alt="Ashley and Shaun Stevenson Shodan Grading - Basics"/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2002/74633_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/Australia2000/Danshagrading2002/9076911_orig.jpg"
                alt="Ashley and Shaun Stevenson's Shodan Grading - Basics"/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryAustaliaTwoThousand;
